html, body, .container {
  height: 100%;
}

.start-screen, .quiz-screen {
  font-size: 1.5rem;
  text-align: center;
  height: 100%;
  display: grid;
  align-items: start;
  flex-direction: column;
  grid-template-columns: 100%;
}

.results-screen {
  text-align: center;
}

.begin-button {
  align-self: center;
}

.question .card {
  min-height: 150px;
}

.question>.card>.card-content>div {
  max-width: 100%;
}

.question>.card p {
  word-wrap: break-word;
}

.answers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.answers.multiple {
  grid-template-columns: 1fr;
}

.answers .answer {
  height: 3rem;
}

.question-result {
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-column-gap: 15px;
  align-items: center;
  text-align: left;
}

.play-again {
  margin-top: 25px;
  margin-bottom: 25px;
}